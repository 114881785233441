.datenschutz {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    font-size: 20px;
    height: auto;
    padding: 2rem 0;
  }
  .datenschutz p
  {
    margin: 15px 0;
  }
  .datenschutz h1
  {
      font-size: 80px;
  }
  .datenschutz h1,.datenschutz h2,.datenschutz h3
  {
      margin: 15px 0;
  }
  .datenschutz ul
  {
      margin-bottom: 1rem;
  }
  .datenschutz ul li
  {
      margin-left: 35px;
  }
  @media screen and (max-width: 960px) {
    .datenschutz>h1 {

        font-size: 60px;

    }
  }

@media screen and (max-width: 768px) {
    .datenschutz {
        margin: 0px;
        padding: 2rem;
        font-size: 14px;
    }

    .datenschutz>h1 {

        font-size: 40px;

    }
   
}