* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home, .datenschutz
{
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.sign-up {

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

input {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  border-radius: 8px;
  border: 1px solid #a4a4a4;
}

label p {
  margin: 10px 0;
}
a {
  font-weight: bold;
  color: #242424;
}