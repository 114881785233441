.wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;

    width: 100%;
    margin: 0 auto;
    font-size: 20px;
    height: auto;
    padding: 2rem 0;
    background-color: rgb(245, 245, 245);
}
.wrapper > div {
    width: 60%;
}
.wrapper fieldset {
    margin: 20px 0;
    border: none;
    display: flex;
    width: 100%;
}
.wrapper label {
    margin-right: 10px;
    flex: 1;
}
.wrapper .shortInput
{
    flex: 0.3;
}
.wrapper .XSInput
{
    flex: 0.2;
}

.btn.submit {
    background-color: transparent;
    border: 1px solid #242424; 
}
.btn.submit:hover {
    background: #242424;
    color: #fff;
    transition: 250ms;
}
.wrapper canvas {
    margin: 0 auto;
    display: block;
}
#downloadLink {
    display: flex;
    align-items: center;
    padding: 10px;
    margin: 20px 0;
}

#downloadLink a
{
    margin: 0px auto;
}

.red {
    color: #f00;
}
.wrapper label.noMargin {
    margin: 0;
}
@media screen and (max-width: 768px) {
    .wrapper > div {
        width: 80%;
    }
}