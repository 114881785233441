.impressum {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1120px;
    width: 100%;
    margin: 0 auto;
    font-size: 20px;
    height: auto;
    padding: 2rem 0;
  }
  .impressum div
  {
      display: block;
      width: 100%;
  }
  .impressum p
  {
    margin: 15px 0;

  }
  .impressum h1
  {
      font-size: 80px;
  }

  .impressum h1,.impressum h2,.impressum h3
  {
      margin: 15px 0;
  }
  .impressum ul
  {
      margin-bottom: 1rem;
  }
  .impressum ul li
  {
      margin-left: 35px;
  }